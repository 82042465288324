
import { defineComponent, onMounted, reactive, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

import moment from "moment";
import store from "@/store";

export default defineComponent({
  name: "CZUKElectricityGeneration",

  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("UK Electricity Generation", [
        "Market Insight",
      ]);
    });

    const chartOptions = {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format("MMM DD");
          },
          style: {
            colors: "#9899ac",
          },
        },

        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: "#9899ac",
          },
          offsetX: 0,
        },
        title: {
          text: "MW",
          style: {
            fontSize: "15px",
            fontWeight: 600,
          },
        },
      },
      grid: {
        show: false,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
        y: [
          {
            formatter: function (val) {
              if (typeof val !== "undefined") {
                return val.toFixed(1) + "k";
              }
              return val;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(1) + "k";
              }
              return y;
            },
          },
          {
            formatter: function (val) {
              if (typeof val !== "undefined") {
                var countDecimals =
                  val % 1 ? val.toString().split(".")[1].length : 0;
                if (countDecimals > 1) {
                  return val.toFixed(7);
                }
              }
              return val;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                var countDecimals =
                  y % 1 ? y.toString().split(".")[1].length : 0;
                if (countDecimals == 1) {
                  return y.toFixed(1) + "k";
                } else if (countDecimals > 1) {
                  return y.toFixed(7);
                } else {
                  return y.toFixed(0) + "k";
                }
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                var countDecimals =
                  y % 1 ? y.toString().split(".")[1].length : 0;
                if (countDecimals == 1) {
                  return y.toFixed(1) + "k";
                } else if (countDecimals > 1) {
                  return y.toFixed(7);
                } else {
                  return y.toFixed(0);
                }
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                var countDecimals =
                  y % 1 ? y.toString().split(".")[1].length : 0;
                if (countDecimals == 1) {
                  return y.toFixed(1) + "k";
                } else if (countDecimals > 1) {
                  return y.toFixed(7);
                } else {
                  return y.toFixed(0) + "k";
                }
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                var countDecimals =
                  y % 1 ? y.toString().split(".")[1].length : 0;
                if (countDecimals == 1) {
                  return y.toFixed(1) + "k";
                } else if (countDecimals > 1) {
                  return y.toFixed(7);
                } else {
                  return y.toFixed(0);
                }
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                var countDecimals =
                  y % 1 ? y.toString().split(".")[1].length : 0;
                if (countDecimals == 1) {
                  return y.toFixed(1) + "k";
                } else if (countDecimals > 1) {
                  return y.toFixed(7);
                } else if (y % 1 === 0 && y === 0) {
                  return y.toFixed(0);
                } else {
                  return y.toFixed(0);
                }
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                var countDecimals =
                  y % 1 ? y.toString().split(".")[1].length : 0;
                if (countDecimals == 1) {
                  return y.toFixed(1) + "k";
                } else if (countDecimals > 1) {
                  return y.toFixed(7);
                } else if (y % 1 === 0 && y === 0) {
                  return y.toFixed(0);
                } else {
                  return y.toFixed(0) + "k";
                }
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                var countDecimals =
                  y % 1 ? y.toString().split(".")[1].length : 0;
                if (countDecimals == 1) {
                  return y.toFixed(1) + "k";
                } else if (countDecimals > 1) {
                  return y.toFixed(7);
                } else {
                  return y.toFixed(0);
                }
              }
              return y;
            },
          },
        ],
      },
      legend: {
        position: "top",
        tooltipHoverFormatter: function (seriesName, opts) {
          if (
            seriesName === "Nuclear" ||
            seriesName === "Biomass" ||
            seriesName === "Wind-metered" ||
            seriesName === "CCGT"
          ) {
            return (
              seriesName +
              " : <strong>" +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              "k" +
              "</strong>"
            );
          } else {
            return (
              seriesName +
              " : <strong>" +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              "</strong>"
            );
          }
        },
      },
    };

    const series = reactive([{}]);
    watch(
      () => store.getters.layoutConfig("toolbar.filterDate"),
      function () {
        getContent();
      }
    );

    /**
     *@description For getting UK Electricity Generation report
     */
    async function getContent() {
      //for Removing the old array before storing new values.
      series.splice(0);
      let year = store.getters.layoutConfig("toolbar.filterDate");
      let momentVariable = moment(year).format("YYYY");

      ApiService.get(
        "api/marketinsight/elecgeneration/getgeneration/" + momentVariable
      )
        .then(async ({ data }) => {
          const response = data;

          //For getting name values
          const headers = response[0];

          //For removing the first row headers from response so can map the fields
          response.splice(0, 1);
          series.push({
            name: headers.f2,
            data: response.map((a) => [new Date(a.f1), a.f2]),
          });
          series.push({
            name: headers.f3,
            data: response.map((a) => [new Date(a.f1), a.f3]),
          });
          series.push({
            name: headers.f4,
            data: response.map((a) => [new Date(a.f1), a.f4]),
          });
          series.push({
            name: headers.f5,
            data: response.map((a) => [new Date(a.f1), a.f5]),
          });
          series.push({
            name: headers.f6,
            data: response.map((a) => [new Date(a.f1), a.f6]),
          });
          series.push({
            name: headers.f7,
            data: response.map((a) => [new Date(a.f1), a.f7]),
          });
          series.push({
            name: headers.f8,
            data: response.map((a) => [new Date(a.f1), a.f8]),
          });
          series.push({
            name: headers.f9,
            data: response.map((a) => [new Date(a.f1), a.f9]),
          });
          series.push({
            name: headers.f10,
            data: response.map((a) => [new Date(a.f1), a.f10]),
          });
          series.push({
            name: headers.f11,
            data: response.map((a) => [new Date(a.f1), a.f11]),
          });
        })
        .catch();
    }
    getContent();
    return {
      series,
      chartOptions,
    };
  },
});
